import {createApp, onMounted, reactive} from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, sameAs, email } from '@vuelidate/validators';
import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
    globalThis.__VUE_OPTIONS_API__ = true;
    globalThis.__VUE_PROD_DEVTOOLS__ = true;
} else {
    // different values for production.
    globalThis.__VUE_OPTIONS_API__ = false;
    globalThis.__VUE_PROD_DEVTOOLS__ = false;
    globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
}

const app = createApp({
    setup() {
        const nonEmpty = (value) => value !== '';
        const state = reactive({
            title: '',
            name: '',
            company: '',
            email: '',
            message: '',
            pr_acc: false,
            submitStatus: '',
        });
        const rules = {
            title: { required, nonEmpty },
            name: { required },
            company: { required },
            email: { required, email },
            message: { required },
            pr_acc: {
                pr_acc: sameAs(true)
            }
        };

        onMounted(() => {
        });

        const v$ = useVuelidate(rules, state);

        async function submitForm() {
            const isFormValid = await v$.value.$validate();
            if (!isFormValid) {
                return;
            }

            const options = {
                method: 'POST',
                data: {
                    title: state.title,
                    name: state.name,
                    company: state.company,
                    email: state.email,
                    message: state.message,
                    pr_acc: state.pr_acc
                },
                url: '/sib/contact'
            };
            axios(options).then(response => {
                console.log(response);
                if (response.data === "OK") {
                    state.submitStatus = "OK";
                } else {
                    state.submitStatus = "ERROR";
                }
            }).catch(() => {
                state.submitStatus = "ERROR";
            });
        }

        return { state, v$, submitForm };
    }
});
app.mount('#contact');